import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Image } from "theme-ui";
import background from "../images/background.png";
import butt from "../images/butt.png";
import StandardButton from "../ui/StandardButton";
import bandaid from "../images/bandaid.png";
import useSound from "use-sound";
import fart1 from "../sounds/fart1.mp3";
import fart2 from "../sounds/fart2.mp3";
import fart3 from "../sounds/fart3.mp3";
import fart4 from "../sounds/fart4.mp3";
import logo from "../images/logo.png";
import pinkBanner from "../images/pinkBanner.png";

export default function SplashScreen(): ReactElement {
  const navigate = useNavigate();
  const farts = [fart1, fart2, fart3, fart4];

  const [currentBandaidRotation, setCurrentBandaidRotation] = useState(0);

  const [currentFartSound, setCurrentFartSound] = useState(
    farts[Math.floor(Math.random() * farts.length)]
  );

  const [play] = useSound(currentFartSound);

  const handleLogoClick = () => {
    (!window.localStorage.getItem("sound") ||
      window.localStorage.getItem("sound") === "true") &&
      play();
    setCurrentFartSound(farts[Math.floor(Math.random() * farts.length)]);
    setCurrentBandaidRotation(
      (currentBandaidRotation) => (currentBandaidRotation += 360)
    );
  };

  return (
    <Flex
      sx={{
        backgroundImage: `url(${background})`,
        objectFit: "contain",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "white",
        gap: "1.2rem",
        border: "3px solid black",
      }}
    >
      <Image
        src={logo}
        onClick={() => window.open("https://www.windscribe.com")}
        sx={{
          position: "absolute",
          top: "10px",
          left: "10px",
          opacity: 0.5,
          height: "30px",
          m: "8px",
          flexShrink: 0,
          cursor: "pointer",
        }}
      />
      <Flex sx={{ position: "relative", height: "8rem", width: "8rem" }}>
        <Image src={butt} sx={{ position: "absolute" }} />
        <Image
          src={bandaid}
          onClick={handleLogoClick}
          sx={{
            position: "absolute",
            cursor: "pointer",
            transform: `rotate(${currentBandaidRotation}deg)`,
            transition: "all 500ms ease-in-out",
          }}
        />
      </Flex>
      <Image src={pinkBanner} sx={{ width: "300px", mt: "0.4rem" }} />
      <StandardButton
        content="Log in"
        onClick={() => {
          navigate("/login");
        }}
      />
    </Flex>
  );
}
