import { ReactElement, useState } from "react";
import { Flex, Image, Input, Text } from "theme-ui";
import background from "../images/background.png";
import butt from "../images/butt.png";
import bandaid from "../images/bandaid.png";
import StandardButton from "../ui/StandardButton";
import fartSound from "../sounds/fart1.mp3";
import useSound from "use-sound";
import { useNavigate } from "react-router-dom";

export default function Login(): ReactElement {
  const [play] = useSound(fartSound);
  const randomPasswords = [
    "123456",
    "123456789",
    "qwerty",
    "password",
    "12345",
    "qwerty123",
    "1q2w3e",
    "12345678",
    "111111",
    "1234567890",
  ];

  const [text, setText] = useState("Forgot password?");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isBandaidRotated, setIsBandaidRotated] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const navigate = useNavigate();

  function handleClick() {
    play();
    setIsBandaidRotated(true);
    setIsLoggingIn(true);
    window.localStorage.setItem("isLoggedIn", "true");
    setTimeout(() => {
      navigate("/main");
    }, 1500);
  }

  return (
    <Flex
      sx={{
        backgroundImage: `url(${background})`,
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "0.4rem",
        color: "black",
        border: "3px solid black",
      }}
    >
      <Flex
        sx={{
          position: "relative",
          height: "8rem",
          width: "8rem",
          zIndex: 1,
          mt: "2px",
        }}
      >
        <Image src={butt} sx={{ position: "absolute" }} />
        <Image
          src={bandaid}
          sx={{
            position: "absolute",
            transform: isBandaidRotated ? "rotate(1080deg)" : "rotate(0deg)",
            transition: "all 1500ms ease-in-out",
          }}
        />
      </Flex>
      {/* Standard view */}
      {!isLoggingIn ? (
        <>
          <Input
            onChange={(e) => setUsername(e.target.value)}
            placeholder="username"
            sx={{ width: "14rem" }}
          />
          <Input
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="password"
            type="password"
            sx={{ width: "14rem" }}
          />
          <Text
            sx={{ userSelect: "none" }}
            onClick={() => {
              setText(
                `Try ${
                  randomPasswords[
                    Math.floor(Math.random() * randomPasswords.length)
                  ]
                }`
              );
            }}
          >
            {text}
          </Text>
          <StandardButton
            content="Log in"
            onClick={handleClick}
            disabled={username === "" || password === ""}
            sx={{ mb: "1px" }}
          />
        </>
      ) : (
        // Logging in/"loading"
        <>
          <Flex
            sx={{
              position: "absolute",
              width: "100vw",
              height: "100vh",
              backgroundColor: "black",
              opacity: 0.8,
              zIndex: 0,
            }}
          ></Flex>
          <Flex sx={{ textShadow: "-1px 1px #ecbdd9", zIndex: 1 }}>
            Logging in...
          </Flex>
        </>
      )}
    </Flex>
  );
}
