import { Dispatch, ReactElement, ReactNode, SetStateAction } from "react";
import { Flex, Text } from "theme-ui";
import StandardButton from "../ui/StandardButton";
import Modal from "./Modal";

interface FAQSectionProps {
  setIsFaqOpen: Dispatch<SetStateAction<boolean>>;
}

export default function FAQSection({
  setIsFaqOpen,
}: FAQSectionProps): ReactElement {
  return (
    <Modal
      headerContent={
        <Flex
          sx={{
            cursor: "pointer",
            width: "100%",
            justifyContent: "center",
          }}
          onClick={() => setIsFaqOpen(false)}
        >
          <Text
            sx={{
              textShadow: "-4px 4px #3989f6",
              fontSize: "3rem",
              color: "white",
            }}
          >
            F.A.Q.
          </Text>
        </Flex>
      }
      bodyContent={
        <Flex sx={{ flexDirection: "column", gap: "1.2rem", p: "1.2rem" }}>
          <FAQ
            question="What is Windpass?"
            answer="Windpass is an ultra-super-duper secure password manager that is built around the core belief that the best way to keep a password safe and secure is to completely forget about it."
          />
          <FAQ
            question="How does Windpass work?"
            answer="Windpass works by completely forgetting all data you put into it, including logins and passwords, so that they’re safe from ever being leaked to anyone, including yourself."
          />
          <FAQ
            question="What are some of the ways I can use Windpass at my job or at home?"
            answer="At work or at home, Windpass securely forgets your passwords no matter where you are."
          />
          <FAQ
            question="Does Windpass store my passwords?"
            answer="Nope, everything you type into Windpass is safely forgotten for all time."
          />
          <FAQ
            question="What happens to my passwords after you've forgotten them?"
            answer="Absolutely nothing. They're gone forever until you can remember them yourself."
          />
          <FAQ
            question="How do I know you're not actually remembering my passwords and just telling me that you're forgetting them?"
            answer="Because that would be a lie. You calling us liars?"
          />
          <FAQ
            question="I'm asking the questions here."
            answer="Oh, yeah. Sorry."
          />
          <FAQ
            question="When I use Windpass, do I get stronger, more attractive, and smell better?"
            answer="Not really. But at least your passwords are safe."
          />
          <FAQ
            question="Is Windpass free?"
            answer="Yes, Windpass is completely free to use (but you’re welcome to give us money if you can)."
          />
          <FAQ
            question="Does Windpass have a Pro version?"
            answer="There is only one version of Windpass, and you’re a pro for using it."
          />
          <FAQ
            question="Why should I use Windpass?"
            answer="Because I said so, that’s why. Jeez."
          />
          <FAQ
            question="How many ounces are there in a pound?"
            answer="I don't know... lots? Maybe try Duck Duck Go for that one."
          />
          <FAQ
            question="When is Father's Day?"
            answer="It was two days ago. Better call him."
          />
          <FAQ
            question="Why do I keep reliving all my most embarrassing moments in my head when I'm lying awake in bed at night?"
            answer="I don't know, but I get that too. It sucks."
          />
          <FAQ
            question="Would you rather fight 100 duck-sized horses, or 1—"
            answer="No. We're not doing that one."
          />
          <FAQ
            question="What's my IP address?"
            answer="If I knew that, I wouldn't tell you."
          />
          <FAQ question="How do I tie a necktie?" answer="Not too tightly." />
          <FAQ
            question="What’s a good cake recipe?"
            answer={
              <Text>
                Oh, I know this one. <br />
                <br />
                - 6oz flour <br />
                - 6oz sugar <br />
                - 6oz butter <br />- 3 eggs
                <br />
                <br /> Mix them all together, pour into two 6-inch pans, and
                bake for 25 minutes at 350° (or until a skewer comes out clean),
                then add icing of your choice.
              </Text>
            }
          />
          <FAQ
            question="How have you been?"
            answer="Tired, but thanks for asking."
          />
          <FAQ
            question="Who is responsible for this?"
            answer="I can’t tell you that... but if you see him, please let us know."
          />
        </Flex>
      }
      footerContent={
        <Flex
          sx={{
            height: "40px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StandardButton
            content="OK"
            sx={{
              height: "30px",
              p: "0.1rem",
              fontSize: "1rem",
              width: "70px",
            }}
            onClick={() => {
              setIsFaqOpen(false);
            }}
          />
        </Flex>
      }
    />
  );
}

interface FAQProps {
  question: string;
  answer: ReactNode;
}

const FAQ = ({ question, answer }: FAQProps): ReactElement => {
  return (
    <Flex
      sx={{
        fontSize: "0.8rem",
        flexDirection: "column",
        lineHeight: "1.4rem",
      }}
    >
      <Text sx={{ color: "#3989f6", fontSize: ".9rem" }}>Q: {question}</Text>
      <Text sx={{ color: "#77390b" }}>A: {answer}</Text>
    </Flex>
  );
};
