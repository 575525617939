import { ReactElement, useEffect } from "react";
import { Flex } from "theme-ui";

export default function DarkMode(): ReactElement {
  //@ts-ignore
  function update(e) {
    var x = e.clientX || e.touches[0].clientX;
    var y = e.clientY || e.touches[0].clientY;

    document.documentElement.style.setProperty("--cursorX", x + "px");
    document.documentElement.style.setProperty("--cursorY", y + "px");
  }

  useEffect(() => {
    document.addEventListener("mousemove", update);
    document.addEventListener("touchmove", update);
  }, []);

  return (
    <Flex
      sx={{
        zIndex: 100,
        display: "block",
        width: "100%",
        height: "100%",
        position: "fixed",
        pointerEvents: "none",
        background: `radial-gradient(
    circle 10vmax at var(--cursorX) var(--cursorY),
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,.5) 80%,
    rgba(0,0,0,1) 100%
  )`,
      }}
    />
  );
}
