import { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Image, Input, Text } from "theme-ui";
import logo from "../images/logo.png";
import blueBanner from "../images/blueBanner.png";
import Settings from "./Settings";
import FAQSection from "./FAQSection";
import RandomFactSection from "./RandomFactSection";
import GiveUsMoneySection from "./GiveUsMoneySection";
import DarkMode from "./DarkMode";
import NuclearOption from "./NuclearOption";
import AboutSection from "./AboutSection";
import music from "../sounds/music.mp3";
import useSound from "use-sound";

interface HeaderButton {
  text: string;
  onClick?: () => void;
}

export default function Main(): ReactElement {
  const navigate = useNavigate();

  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isFactOpen, setIsFactOpen] = useState(false);
  const [isMoneyOpen, setIsMoneyOpen] = useState(false);
  const [isDarkModeOn, setIsDarkModeOn] = useState(false);
  const [isNuclearOptionOpen, setIsNuclearOptionOpen] = useState(false);
  const [play, { stop }] = useSound(music);
  const [hintOpacity, setHintOpacity] = useState(0);
  const [attempts, setAttempts] = useState(0);

  const headerButtons: HeaderButton[] = [
    {
      text: "Help!",
      onClick: () => {
        window.open("https://youtu.be/TTa1alT8ZG4");
      },
    },
    { text: "F.A.Q.", onClick: () => setIsFaqOpen(true) },
    { text: "About", onClick: () => setIsAboutOpen(true) },
    { text: "Random fact", onClick: () => setIsFactOpen(true) },
    { text: "Give us money", onClick: () => setIsMoneyOpen(true) },
  ];

  const passwords: string[] = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ];

  const possibleGoneMessages = [
    "Obliterated!",
    "Disremembered!",
    "Poof! Gone!",
    "Forgotten!",
    "Wiped out!",
    "Disappeared!",
    "Expunged!",
    "Dead!",
    "No more!",
    `...and it's gone!`,
  ];

  const passwordRefs = useRef<HTMLInputElement[]>([]);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useEffect(() => {
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Flex
      sx={{
        width: "100%",
        height: "100%",
        p: 0,
        backgroundColor: "#ecbdd9",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Flex
        sx={{
          px: "8px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Flex sx={{ alignItems: "center" }}>
          <Image
            src={logo}
            onClick={() => window.open("https://www.windscribe.com")}
            sx={{
              opacity: 0.5,
              height: "30px",
              m: "8px",
              flexShrink: 0,
              cursor: "pointer",
            }}
          />
          {headerButtons.map((button) => (
            <Flex
              sx={{
                fontFamily: "IBM Plex Sans",
                color: "#77390b",
                pr: "12px",
                cursor: "pointer",
                fontSize: ".7rem",
                userSelect: "none",
              }}
              onClick={button.onClick}
            >
              {button.text}
            </Flex>
          ))}
        </Flex>
        <Image src={blueBanner} sx={{ width: "180px" }} />
      </Flex>
      <Flex
        sx={{
          height: "35px",
          pl: "40px",
          alignItems: "flex-start",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <Text
          sx={{
            opacity: hintOpacity,
            cursor: "default",
            transition: "all 500ms ease-out",
          }}
        >
          Hint: what's an elegant way to describe farting?
        </Text>
      </Flex>
      {/* Main Section */}
      <Flex
        sx={{
          pl: "40px",
          flexWrap: "wrap",
          rowGap: "12px",
          columnGap: "16px",
        }}
      >
        {passwords.map((_, index) => (
          <Input
            type={
              !!window.localStorage.getItem("show_passwords") &&
              window.localStorage.getItem("show_passwords") === "true"
                ? "text"
                : "password"
            }
            placeholder={"Password"}
            //@ts-ignore
            ref={(el) => (passwordRefs.current[index] = el)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (
                  passwordRefs.current[index].value
                    .toLowerCase()
                    .replace(" ", "") !== "passwind"
                ) {
                  console.log(attempts);
                  setAttempts((attempts) => {
                    if (attempts >= 2) {
                      setHintOpacity(1);
                      setTimeout(() => {
                        setHintOpacity(0);
                      }, 3000);
                    }
                    return attempts + 1;
                  });
                } else {
                  // they got the hint right
                  window.open(
                    "https://windscribe.com/upgrade?promo=PASSWIND&pcpid=2023_aprilfools"
                  );
                }
                passwordRefs.current[index].type = "text";
                passwordRefs.current[index].style.fontSize = "0.1rem";
                passwordRefs.current[index].style.transition = "0s all";

                passwordRefs.current[index].style.transition = "1s all";
                passwordRefs.current[index].value =
                  possibleGoneMessages[
                    Math.floor(Math.random() * possibleGoneMessages.length)
                  ];
                passwordRefs.current[index].blur();
                passwordRefs.current[index].style.color = "rgba(0, 0, 0, 0)";

                setTimeout(() => {
                  passwordRefs.current[index].style.transition = "0s all";
                  passwordRefs.current[index].value = "";
                  passwordRefs.current[index].style.color = "rgba(0, 0, 0, 1)";
                  passwordRefs.current[index].style.fontSize = "1.4rem";
                  passwordRefs.current[index].type =
                    !!window.localStorage.getItem("show_passwords") &&
                    window.localStorage.getItem("show_passwords") === "true"
                      ? "text"
                      : "password";
                }, 1000);
              }
            }}
            onBlur={(e) => {
              if (
                passwordRefs.current[index].value
                  .toLowerCase()
                  .replace(" ", "") !== "passwind"
              ) {
                console.log(attempts);
                setAttempts((attempts) => {
                  if (attempts >= 2) {
                    setHintOpacity(1);
                    setTimeout(() => {
                      setHintOpacity(0);
                    }, 3000);
                  }
                  return attempts + 1;
                });
              } else {
                // they got the hint right
                window.open(
                  "https://windscribe.com/upgrade?promo=PASSWIND&pcpid=2023_aprilfools"
                );
              }

              if (e.target.value !== "") {
                passwordRefs.current[index].type = "text";
                passwordRefs.current[index].style.fontSize = "0.1rem";
                passwordRefs.current[index].style.transition = "0s all";

                passwordRefs.current[index].style.transition = "1s all";
                passwordRefs.current[index].value =
                  possibleGoneMessages[
                    Math.floor(Math.random() * possibleGoneMessages.length)
                  ];
                passwordRefs.current[index].blur();
                passwordRefs.current[index].style.color = "rgba(0, 0, 0, 0)";

                setTimeout(() => {
                  passwordRefs.current[index].style.transition = "0s all";
                  passwordRefs.current[index].value = "";
                  passwordRefs.current[index].style.color = "rgba(0, 0, 0, 1)";
                  passwordRefs.current[index].style.fontSize = "1.4rem";
                  passwordRefs.current[index].type =
                    !!window.localStorage.getItem("show_passwords") &&
                    window.localStorage.getItem("show_passwords") === "true"
                      ? "text"
                      : "password";
                }, 1000);
              }
            }}
            sx={{
              fontSize: "1.4rem",
              p: "0.2rem",
              backgroundColor: "white",
              color: "black",
              borderRadius: "30px",
              border: "3px solid black",
              alignItems: "center",
              textAlign: "center",
              maxWidth: "155px",
              maxHeight: "41px",
              fontFamily: "IBM Plex Sans",
              cursor: "pointer",
              transition: "1s all",
            }}
          />
        ))}
      </Flex>
      <Settings
        isOpen={isSettingsOpen}
        setIsOpen={setIsSettingsOpen}
        isDarkModeOn={isDarkModeOn}
        setIsDarkModeOn={setIsDarkModeOn}
        setIsNuclearOptionOpen={setIsNuclearOptionOpen}
        playMusic={play}
        stopMusic={stop}
      />
      {isFaqOpen && <FAQSection setIsFaqOpen={setIsFaqOpen} />}
      {isFactOpen && <RandomFactSection setIsFactOpen={setIsFactOpen} />}
      {isMoneyOpen && <GiveUsMoneySection setIsMoneyOpen={setIsMoneyOpen} />}
      {isDarkModeOn && <DarkMode />}
      {isNuclearOptionOpen && (
        <NuclearOption
          setIsNuclearOptionOpen={setIsNuclearOptionOpen}
          stopMusic={stop}
        />
      )}
      {isAboutOpen && <AboutSection setIsAboutOpen={setIsAboutOpen} />}
    </Flex>
  );
}
