import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Flex, Image } from "theme-ui";
import butt from "../images/WindpassLogoWithPink.png";
import bandaid from "../images/bandaid.png";
import blueTab from "../images/WPBlueTabTall.png";
import StandardButton from "../ui/StandardButton";
import logOut from "../images/LogOutXIcon.png";
import NukeIcon from "../images/NukeIconWithPink.png";
import { useNavigate } from "react-router-dom";
import { WINDOW_HEIGHT, WINDOW_WIDTH } from "./AppWrapper";
import fart1 from "../sounds/fart1.mp3";
import fart2 from "../sounds/fart2.mp3";
import fart3 from "../sounds/fart3.mp3";
import fart4 from "../sounds/fart4.mp3";

import useSound from "use-sound";
import { PlayFunction } from "use-sound/dist/types";

interface SettingsProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsDarkModeOn: Dispatch<SetStateAction<boolean>>;
  isDarkModeOn: boolean;
  setIsNuclearOptionOpen: Dispatch<SetStateAction<boolean>>;
  playMusic: PlayFunction;
  stopMusic: (id?: string | undefined) => void;
}

export default function Settings({
  isOpen,
  setIsOpen,
  setIsDarkModeOn,
  isDarkModeOn,
  setIsNuclearOptionOpen,
  playMusic,
  stopMusic,
}: SettingsProps): ReactElement {
  const navigate = useNavigate();
  const farts = [fart1, fart2, fart3, fart4];
  const [currentBandaidRotation, setCurrentBandaidRotation] = useState(0);
  const [fartClicks, setFartClicks] = useState(0);
  const [fartCounter, setFartCounter] = useState(
    !!window.localStorage.getItem("fart_counter")
      ? window.localStorage.getItem("fart_counter") === "true"
      : false
  );

  const [musicTimer, setMusicTimer] = useState(
    !!window.localStorage.getItem("music_timer")
      ? window.localStorage.getItem("music_timer") === "true"
      : false
  );

  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [timeElapsedWhilePlayingMusic, setTimeElapsedWhilePlayingMusic] =
    useState(0);

  const [currentFartSound, setCurrentFartSound] = useState(
    farts[Math.floor(Math.random() * farts.length)]
  );

  const [play] = useSound(currentFartSound);

  const handleLogoClick = () => {
    (window.localStorage.getItem("sound") === "true" ||
      !window.localStorage.getItem("sound")) &&
      play();
    window.localStorage.getItem("fart_counter") === "true" &&
      setFartClicks((fartClicks) => fartClicks + 1);
    setCurrentFartSound(farts[Math.floor(Math.random() * farts.length)]);
    setCurrentBandaidRotation(
      (currentBandaidRotation) => (currentBandaidRotation += 360)
    );
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (musicTimer && isMusicPlaying) {
        setTimeElapsedWhilePlayingMusic(
          (timeElapsedWhilePlayingMusic) => timeElapsedWhilePlayingMusic + 1
        );
      } else {
        clearInterval(timerInterval);
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [musicTimer, isMusicPlaying]);

  return (
    <Flex sx={{ position: "relative" }}>
      <Image
        src={blueTab}
        sx={{
          width: `${WINDOW_WIDTH}px`,
          height: `${WINDOW_HEIGHT}px`,
          bottom: isOpen ? "-50px" : "-360px",
          position: "fixed",
          left: 0,
          transition: "all 300ms ease-in-out",
        }}
      />
      <Flex
        sx={{
          position: "fixed",
          zIndex: 3,
          width: "100%",
          bottom: isOpen ? "320px" : "15px",
          left: 0,
          transition: "all 300ms ease-in-out",
          cursor: "pointer",
        }}
      >
        <Image
          src={butt}
          sx={{ position: "absolute", width: "90px", bottom: "-5px" }}
        />
        <Image
          src={bandaid}
          onClick={handleLogoClick}
          sx={{
            position: "absolute",
            width: "90px",
            transform: `rotate(${currentBandaidRotation}deg)`,
            transition: "all 500ms ease-in-out",
            bottom: "-5px",
          }}
        />
        {fartCounter && (
          <Flex
            sx={{
              position: "fixed",
              bottom: isOpen ? "390px" : "82px",
              transition: "all 300ms",
              color: "white",
              fontWeight: "bold",
              left: "100px",
            }}
          >
            Fart Counter: {fartClicks}
          </Flex>
        )}
        {musicTimer && (
          <Flex
            sx={{
              position: "fixed",
              bottom: isOpen ? "390px" : "82px",
              transition: "all 300ms",
              color: "white",
              fontWeight: "bold",
              right: "10px",
            }}
          >
            Music Timer: {timeElapsedWhilePlayingMusic}
          </Flex>
        )}

        <Flex
          sx={{
            position: "relative",
            left: "130px",
            top: "10px",
            width: "400px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <StandardButton
            hasPinkBit
            content={isOpen ? "Close" : "Settings"}
            onClick={() => {
              setIsOpen((isOpen) => !isOpen);
            }}
            sx={{ height: "40px", width: "140px" }}
          />
          <Image
            onClick={() => {
              setIsNuclearOptionOpen(true);
            }}
            src={NukeIcon}
            sx={{
              cursor: "pointer",
              height: "60px",
            }}
          />
          <StandardButton
            hasPinkBit
            content={
              <Flex sx={{ alignItems: "center", gap: "8px" }}>
                <Image src={logOut} sx={{ width: "20px" }} />
                Log out
              </Flex>
            }
            onClick={() => {
              window.localStorage.setItem("isLoggedIn", "false");
              navigate("/splash");
            }}
            sx={{ height: "40px", width: "140px" }}
          />
        </Flex>

        <Flex
          sx={{
            top: isOpen ? "180px" : "600px",
            position: "fixed",
            left: "40px",
            flexDirection: "column",
            alignItems: "flex-end",
            transition: "all 300ms ease-in-out",
            gap: "10px",
          }}
        >
          <SettingWithOnOffToggle
            text={"Auto-Forget"}
            enabled={true}
            offText="On"
          />
          <SettingWithOnOffToggle
            text={"Show Passwords"}
            enabled={
              !!window.localStorage.getItem("show_passwords")
                ? window.localStorage.getItem("show_passwords") === "true"
                : false
            }
            handleClick={(enabled) => {
              window.localStorage.setItem(
                "show_passwords",
                enabled ? "true" : "false"
              );
            }}
          />
          <SettingWithOnOffToggle
            text={"Sound"}
            enabled={
              !!window.localStorage.getItem("sound")
                ? window.localStorage.getItem("sound") === "true"
                : true
            }
            handleClick={(enabled) => {
              window.localStorage.setItem("sound", enabled ? "true" : "false");
            }}
          />
          <MusicSetting
            isMusicPlaying={isMusicPlaying}
            setIsMusicPlaying={setIsMusicPlaying}
            play={playMusic}
            stop={stopMusic}
          />
        </Flex>

        <Flex
          sx={{
            top: isOpen ? "180px" : "600px",
            right: "40px",
            position: "fixed",
            flexDirection: "column",
            alignItems: "flex-end",
            transition: "all 300ms ease-in-out",
            gap: "10px",
          }}
        >
          <SettingWithOnOffToggle
            text={"Dark Mode"}
            enabled={isDarkModeOn}
            handleClick={() => setIsDarkModeOn((isDarkModeOn) => !isDarkModeOn)}
          />
          <SettingWithOnOffToggle
            text={"Fart Counter"}
            enabled={fartCounter}
            handleClick={(enabled) => {
              setFartCounter(enabled);
              window.localStorage.setItem(
                "fart_counter",
                enabled ? "true" : "false"
              );
            }}
          />
          <SettingWithOnOffToggle
            text={"Music Timer"}
            enabled={musicTimer}
            handleClick={(enabled) => {
              setMusicTimer(enabled);
              window.localStorage.setItem(
                "music_timer",
                enabled ? "true" : "false"
              );
              //if they're disabling it, reset the timer
              if (!enabled) {
                setTimeElapsedWhilePlayingMusic(0);
              }
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

interface SettingWithOnOffToggleProps {
  enabled: boolean;
  text: string;
  offText?: string;
  handleClick?: (enabled: boolean) => void;
}

function SettingWithOnOffToggle({
  enabled,
  text,
  offText,
  handleClick,
}: SettingWithOnOffToggleProps): ReactElement {
  const [isEnabled, setIsEnabled] = useState(enabled);
  return (
    <Flex sx={{ alignItems: "center" }}>
      <Flex
        sx={{
          pr: "6px",
          fontSize: "18px",
          fontFamily: "IBM Plex Sans",
          textShadow: "-1px 1px #ecbdd9",
          color: "white",
          mr: "0.4rem",
        }}
      >
        {text}
      </Flex>
      <StandardButton
        hasPinkBit
        content={isEnabled ? "Yes" : offText ?? "No"}
        onClick={() => {
          handleClick && handleClick(!isEnabled);
          setIsEnabled((isEnabled) => !isEnabled);
        }}
        sx={{ width: "100px", fontFamily: "IBM Plex Sans" }}
      />
    </Flex>
  );
}

interface MusicSettingProps {
  isMusicPlaying: boolean;
  setIsMusicPlaying: Dispatch<SetStateAction<boolean>>;
  play: PlayFunction;
  stop: (id?: string | undefined) => void;
}

function MusicSetting({
  isMusicPlaying,
  setIsMusicPlaying,
  play,
  stop,
}: MusicSettingProps): ReactElement {
  useEffect(() => {
    if (isMusicPlaying) {
      play();
    }
    const timerInterval = setInterval(() => {
      if (isMusicPlaying) {
        play();
      } else {
        clearInterval(timerInterval);
        stop();
      }
    }, 7197);

    if (!isMusicPlaying) {
      stop();
    }

    return () => clearInterval(timerInterval);
  }, [isMusicPlaying, play, stop]);

  return (
    <SettingWithOnOffToggle
      text={"Music"}
      enabled={isMusicPlaying}
      handleClick={() => {
        setIsMusicPlaying((isMusicPlaying) => !isMusicPlaying);
      }}
    />
  );
}
