import { ReactElement, ReactNode, useEffect } from "react";

export const WINDOW_WIDTH = 573;
export const WINDOW_HEIGHT = 466;

export default function AppWrapper({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  useEffect(() => {
    window.addEventListener("resize", () => {
      window.resizeTo(573, 520);
    });
  }, []);
  return <>{children}</>;
}
