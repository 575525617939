import { Dispatch, ReactElement, SetStateAction } from "react";
import { Flex, Text } from "theme-ui";
import StandardButton from "../ui/StandardButton";
import Modal from "./Modal";

interface RandomFactSectionProps {
  setIsFactOpen: Dispatch<SetStateAction<boolean>>;
}

export default function RandomFactSection({
  setIsFactOpen,
}: RandomFactSectionProps): ReactElement {
  const facts: string[] = [
    "The Statue of Liberty was originally designed as a huge paperweight.",
    "There are seven letters in the word 'seven'.",
    "92% of the world's greenhouse gasses are caused by your mom.",
    "12% of cows are actually invisible.",
    "Contrary to popular belief, Albert Einstein did not have a pet duck.",
    "In 1882, the sport of Onionball was invented, and then immediately banned due to food shortages.",
    "The Mona Lisa was painted with one brush stroke.",
    "I'm Batman.",
    "Christopher Nolan's Tenet was the first film to be directed entirely in reverse.",
    "I came up with most of these facts on a Monday.",
    "We're out of printer ink.",
    "Every sentence contains the letter 'e'.",
    "The English alphabet has 16 'secret' letters, which are only used by members of parliament.",
    "The Dunning-Kruger Effect doesn't apply to smart people.",
    "The universe is a preordained experience distributed across billions of disparate viewpoints spanning time and space as perceived through the mind of just one person... and that person is you. Cheers.",
    "The dress is blue, and don't ever let anyone tell you otherwise.",
    "In 1978, six pigeons once infiltrated the Pentagon, disguised as a much larger pigeon wearing a suit.",
    "Napoleon Bonaparte performed the very first 'double take'.",
    "On April 9th, 1996, Al Gore ate an entire package of fig newtons.",
    "Margaret Thatcher is credited as being the first woman to give birth to herself.",
    "You're going to click that OK button when you're done reading this.",
    "Cats have an extra, secret 10th life that they don't talk about.",
    "If you play Ludwig van Beethoven's Ode to Joy backwards, it sounds like shit.",
    "In 1961, nothing much happened.",
    "In 2019, scientists discovered the existence of a second Earth hiding behind the moon, identical to this Earth in every way, except that everyone on it looks like Michael Stipe.",
    "The Earth's population is around 8 billion... that's a lot.",
    "Roughly 2% of all birds are actually robotic surveillance drones, operated by other birds working from home.",
    "When the Fart Counter hits 2,147,483,647, Windpass will crash.",
  ];
  return (
    <Modal
      height="210px"
      top="130px"
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
      bodyContent={
        <Flex sx={{ flexDirection: "column", color: "#77390b" }}>
          <Text sx={{ fontSize: "1rem", pb: "1.2rem", px: "1rem" }}>
            {facts[Math.floor(Math.random() * facts.length)]}
          </Text>
          <Flex
            sx={{
              height: "40px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StandardButton
              content="OK"
              sx={{
                height: "40px",
                p: "0.1rem",
                fontSize: "1.4rem",
                width: "100px",
              }}
              onClick={() => {
                setIsFactOpen(false);
              }}
            />
          </Flex>
        </Flex>
      }
    />
  );
}
