import { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { Flex, Input, Text } from "theme-ui";
import StandardButton from "../ui/StandardButton";
import Modal from "./Modal";
import { keyframes } from "@emotion/react";

interface GiveUsMoneySectionProps {
  setIsMoneyOpen: Dispatch<SetStateAction<boolean>>;
}

export default function GiveUsMoneySection({
  setIsMoneyOpen,
}: GiveUsMoneySectionProps): ReactElement {
  const [isStupid, setIsStupid] = useState(false);
  const [shake, setShake] = useState(false);

  const shakeKeyframes = keyframes`
  0% {
    transform: translate(6px, 0px);
  }
  50% {
    transform: translate(-6px, 0px);
  }
  100% {
    transform: translate(6px, 0px);
  }
`;

  return (
    <Modal
      height="290px"
      top="50px"
      width="420px"
      left="75px"
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "#77390b",
        fontFamily: "IBM Plex Sans",
      }}
      outerSx={{
        animation: shake ? `${shakeKeyframes} 0.1s linear infinite` : "",
      }}
      bodyContent={
        !isStupid ? (
          <Flex sx={{ flexDirection: "column" }}>
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
                gap: "1.2rem",
                px: "4rem",
                pb: "1.4rem",
              }}
            >
              <Text sx={{ fontSize: "1rem", px: "1rem" }}>
                Credit card info, please:
              </Text>
              <Input
                placeholder="Card no."
                sx={{ backgroundColor: "white", border: "2px solid black" }}
                onChange={() => {
                  setShake(true);
                  setIsStupid(true);
                  setTimeout(() => {
                    setShake(false);
                  }, 500);
                }}
              />
              <Flex sx={{ alignItems: "center" }}>
                <Input
                  onChange={() => {
                    setShake(true);
                    setIsStupid(true);
                    setTimeout(() => {
                      setShake(false);
                    }, 500);
                  }}
                  placeholder="Exp."
                  sx={{
                    width: "70px",
                    mr: "1.2rem",
                    backgroundColor: "white",
                    border: "2px solid black",
                  }}
                />
                Sec code:{" "}
                <Input
                  onChange={() => {
                    setShake(true);
                    setIsStupid(true);
                    setTimeout(() => {
                      setShake(false);
                    }, 500);
                  }}
                  sx={{
                    ml: "0.8rem",
                    width: "60px",
                    backgroundColor: "white",
                    border: "2px solid black",
                  }}
                />
              </Flex>
            </Flex>
            <Flex
              sx={{
                height: "40px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                gap: "1.2rem",
              }}
            >
              <StandardButton
                content="OK"
                sx={{
                  height: "40px",
                  p: "0.1rem",
                  fontSize: "1.4rem",
                  width: "160px",
                }}
                onClick={() => {
                  setIsMoneyOpen(false);
                }}
              />{" "}
              <StandardButton
                content="Cancel"
                sx={{
                  height: "40px",
                  p: "0.1rem",
                  fontSize: "1.4rem",
                  width: "160px",
                }}
                onClick={() => {
                  setIsMoneyOpen(false);
                }}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex sx={{ flexDirection: "column" }}>
            <Flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
                gap: "1.2rem",
                px: "4rem",
                pb: "1.4rem",
              }}
            >
              <Text sx={{ fontSize: "1rem", px: "1rem" }}>
                What are you, nuts? Don't just go typing your credit card info
                into some random box! Jeez! What's wrong with you?
              </Text>
            </Flex>
            <Flex
              sx={{
                height: "40px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                gap: "1.2rem",
              }}
            >
              <StandardButton
                content="My bad"
                sx={{
                  height: "40px",
                  p: "0.1rem",
                  fontSize: "1.4rem",
                  width: "160px",
                }}
                onClick={() => {
                  setIsMoneyOpen(false);
                }}
              />
            </Flex>
          </Flex>
        )
      }
    />
  );
}
