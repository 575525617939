import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import SplashScreen from "./components/SplashScreen";
import Login from "./components/Login";
import { Flex } from "theme-ui";
import Main from "./components/Main";
import AppWrapper from "./components/AppWrapper";
import "./index.css";
import "@fontsource/ibm-plex-sans/500.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "splash",
    element: (
      <AppWrapper>
        <SplashScreen />
      </AppWrapper>
    ),
  },
  {
    path: "login",
    element: (
      <AppWrapper>
        <Login />
      </AppWrapper>
    ),
  },
  {
    path: "main",
    element: (
      <AppWrapper>
        <Main />
      </AppWrapper>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Flex
      sx={{
        width: "100%",
        height: "97.5vh",
      }}
    >
      <RouterProvider router={router} />
    </Flex>
  </React.StrictMode>
);
