import { ReactElement, ReactNode } from "react";
import { Button, ThemeUIStyleObject } from "theme-ui";

interface ButtonProps {
  content: ReactNode;
  onClick: () => void;
  sx?: ThemeUIStyleObject;
  disabled?: boolean;
  hasPinkBit?: boolean;
}

export default function StandardButton({
  content,
  onClick,
  sx,
  disabled,
  hasPinkBit,
}: ButtonProps): ReactElement {
  return (
    <Button
      sx={{
        boxShadow: hasPinkBit ? "-3px 3px #ecbdd9" : "",
        backgroundColor: "white",
        color: "black",
        borderRadius: "30px",
        border: "3px solid black",
        alignItems: "center",
        textAlign: "center",
        width: "155px",
        fontFamily: "IBM Plex Sans",
        fontSize: "1rem",
        ...sx,
        cursor: "pointer",
        transition: "300ms all",
        ":disabled": {
          borderColor: "transparent",
          backgroundColor: "grey",
          transition: "300ms all",
          cursor: "auto",
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
  );
}
