import { ReactElement, useState } from "react";
import { Flex, Image } from "theme-ui";
import { WINDOW_HEIGHT, WINDOW_WIDTH } from "../components/AppWrapper";
import StandardButton from "../ui/StandardButton";
import pinkBanner from "../images/pinkBanner.png";
import clouds from "../images/clouds.png";
import { keyframes } from "@emotion/react";
import butt from "../images/butt.png";
import bandaid from "../images/bandaid.png";
import fart1 from "../sounds/fart1.mp3";
import fart2 from "../sounds/fart2.mp3";
import fart3 from "../sounds/fart3.mp3";
import fart4 from "../sounds/fart4.mp3";
import useSound from "use-sound";
import blueIcon from "../images/blueIcon.png";

// Blue: 3989f6
// Pink: ecbdd9
// brown: 77390b

const slide = keyframes`
0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(1920px, 0, 0); /* The image width */
  }
`;

export default function Root(): ReactElement {
  const openPositionY = window.screen.height / 2 - WINDOW_HEIGHT / 2;
  const openPositionX = window.screen.width / 2 - WINDOW_HEIGHT / 2;

  const farts = [fart1, fart2, fart3, fart4];

  const [currentBandaidRotation, setCurrentBandaidRotation] = useState(0);

  const [currentFartSound, setCurrentFartSound] = useState(
    farts[Math.floor(Math.random() * farts.length)]
  );

  const [play] = useSound(currentFartSound);

  const handleLogoClick = () => {
    (!window.localStorage.getItem("sound") ||
      window.localStorage.getItem("sound") === "true") &&
      play();
    setCurrentFartSound(farts[Math.floor(Math.random() * farts.length)]);
    setCurrentBandaidRotation(
      (currentBandaidRotation) => (currentBandaidRotation += 360)
    );
  };

  return (
    <Flex
      sx={{
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",

        backgroundImage: `linear-gradient(to top right, #3989f6,#ecbdd9)`,
        overflow: "hidden",
      }}
    >
      <Flex
        sx={{
          width: "5760px",
          height: "1080px",
          background: `url(${clouds}) repeat-x`,
          objectFit: "contain",
          animation: `${slide} 120s linear infinite`,
          zIndex: 0,
          position: "fixed",
          bottom: 0,
        }}
      />
      <Flex
        sx={{
          position: "relative",
          height: "8rem",
          width: "8rem",
          display: ["none", "flex"],
        }}
      >
        <Image src={butt} sx={{ position: "absolute" }} />
        <Image
          src={bandaid}
          onClick={handleLogoClick}
          sx={{
            position: "absolute",
            cursor: "pointer",
            transform: `rotate(${currentBandaidRotation}deg)`,
            transition: "all 500ms ease-in-out",
          }}
        />
      </Flex>
      <Image src={pinkBanner} sx={{ width: "600px", mt: "0.4rem" }} />

      <Flex
        sx={{
          width: ["80%", "600px"],
          textAlign: "center",
          zIndex: 10,
          color: "white",
          mt: "24px",
          mb: "48px",
          fontWeight: "400",
          textShadow: "-2px 2px 2px #0e3d80",
          display: ["none", "flex"],
        }}
      >
        Windpass is the world's first password manager that forgets your
        passwords so you don't have to. Worried about hackers leaking your
        information? With Windpass, there's nothing there to steal.
      </Flex>
      <Flex
        sx={{
          display: ["none", "flex"],
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <StandardButton
          content="Launch"
          hasPinkBit
          onClick={() =>
            window.open(
              window.location.origin + "/splash",
              "_blank",
              `toolbar=0,location=0,menubar=0,resizable=no,width=${WINDOW_WIDTH},height=${WINDOW_HEIGHT}, top=${openPositionY}, left=${openPositionX}`
            )
          }
          sx={{ zIndex: 10, mb: "24px" }}
        />

        <Flex
          sx={{
            background: "rgba(76, 149, 247, 0.25)",
            color: "white",
            zIndex: 10,
            borderRadius: "8px",
            px: "16px",
            py: "8px",
          }}
        >
          First 25k people get 44% off Windscribe Pro
        </Flex>
      </Flex>
      <Flex
        sx={{
          display: ["flex", "none"],
          px: "1.2rem",
          color: "#77390b",
          zIndex: 10,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: ["8px", "2.4rem"],
          fontWeight: "400",
          mt: ["8px", 0],
        }}
      >
        Listen, we were going to make Windpass work on vertical screens, but we,
        uh... forgot. If you need more convincing, check out our video on
        YouTube. I heard that works on phones now.
        <StandardButton
          hasPinkBit
          onClick={() => {
            window.open("https://youtu.be/TTa1alT8ZG4");
          }}
          content="Bleh"
        />
      </Flex>
      <Flex
        sx={{
          position: "absolute",
          bottom: "24px",
          alignItems: "center",
          color: "#4C95F7",
          width: ["80%", "360px"],
        }}
      >
        <Image
          src={blueIcon}
          sx={{
            zIndex: 10,
            opacity: 0.75,
            flexShrink: 0,
          }}
        />
        <Flex
          sx={{
            fontSize: "12px",
            pl: "24px",
            ml: "24px",
            borderLeft: "1px solid rgba(76, 149, 247, 0.25)",
            fontWeight: "400",
          }}
        >
          Disclaimer: This app does absolutely nothing and does not offer any
          security features whatsoever.
        </Flex>
      </Flex>
    </Flex>
  );
}
