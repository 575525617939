import { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { Flex, Image } from "theme-ui";
import StandardButton from "../ui/StandardButton";
import Modal from "./Modal";
import useSound from "use-sound";
import nuclearFart from "../sounds/nuclearfart.mp3";
import skull from "../images/NukeIconSkullBG.png";
import nukeBg from "../images/NukeIconBG.png";

interface NuclearOptionProps {
  setIsNuclearOptionOpen: Dispatch<SetStateAction<boolean>>;
  stopMusic: (id?: string | undefined) => void;
}

export default function NuclearOption({
  setIsNuclearOptionOpen,
  stopMusic,
}: NuclearOptionProps): ReactElement {
  const steps: string[] = [
    "Are you sure?",
    "No, seriously... are you absolutely sure?",
    "Look, I'm just letting you know... it's probably not worth it. Are you completely sure this is what you want?",
    "Okay. I'm gonna level with you... we've never actually used this feature. We developed it, we put it in, and then we left it alone. We're pretty sure we know what it does, but we don't want to risk finding out the hard way. Do you?",
    "Oh my god... you're actually going to do it, aren't you?",
    "Sigh... all right then. Last chance. Are you 100% sure you want to do this?",
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [isNuclearFarting, setIsNuclearFarting] = useState(false);
  const [hasNuclearFarted, setHasNuclearFarted] = useState(false);
  const [play] = useSound(nuclearFart);

  const fartNuclearly = () => {
    setIsNuclearFarting(true);
    play();
    setTimeout(() => {
      setIsNuclearFarting(false);
      setHasNuclearFarted(true);
      stopMusic();
      setTimeout(() => {
        window.close();
      }, 2500);
    }, 37801);
  };

  return (
    <>
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "#77390b",
          opacity: isNuclearFarting || hasNuclearFarted ? 1 : 0,
          zIndex: isNuclearFarting || hasNuclearFarted ? 10 : -1,
          transition: "37801ms opacity",
        }}
      />
      {!isNuclearFarting && (
        <Modal
          height="290px"
          top="80px"
          width="420px"
          left="75px"
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          bodyContent={
            hasNuclearFarted ? (
              <>
                <Image
                  sx={{
                    position: "fixed",
                    top: "90px",
                    left: "90px",
                    height: "270px",
                    width: "390px",
                  }}
                  src={skull}
                />
                <Flex>You monster.</Flex>
              </>
            ) : (
              <>
                <Image
                  sx={{
                    position: "fixed",
                    top: "90px",
                    left: "95px",
                    height: "270px",
                    width: "380px",
                    zIndex: -2,
                  }}
                  src={nukeBg}
                />
                <Flex
                  sx={{ flexDirection: "column", gap: "2.4rem", p: "1.2rem" }}
                >
                  {steps[currentStep]}
                  <Flex sx={{ gap: "1.2rem", justifyContent: "center" }}>
                    <StandardButton
                      content="Yes"
                      onClick={() => {
                        if (currentStep !== steps.length - 1) {
                          setCurrentStep((currentStep) => currentStep + 1);
                        } else {
                          fartNuclearly();
                        }
                      }}
                    />
                    <StandardButton
                      content="No"
                      onClick={() => {
                        setIsNuclearOptionOpen(false);
                      }}
                    />
                  </Flex>
                </Flex>
              </>
            )
          }
        />
      )}
    </>
  );
}
