import { Dispatch, ReactElement, SetStateAction } from "react";
import { Flex, Text } from "theme-ui";
import StandardButton from "../ui/StandardButton";
import Modal from "./Modal";

interface AboutSectionProps {
  setIsAboutOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AboutSection({
  setIsAboutOpen,
}: AboutSectionProps): ReactElement {
  return (
    <Modal
      headerContent={
        <Flex
          sx={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Text
            sx={{
              textShadow: "-4px 4px #3989f6",
              fontSize: "3rem",
              color: "white",
            }}
          >
            About
          </Text>
        </Flex>
      }
      bodyContent={
        <Flex
          sx={{
            flexDirection: "column",
            gap: "1.2rem",
            p: "1.2rem",
            fontFamily: "IBM Plex Sans",
            color: "#77390b",
          }}
        >
          Hey everyone,
          <br /> <br />
          <br /> Ok, seriously. Windpass is satire. That means it's not real.
          It's a joke.
          <br /> <br />
          <br /> It does NOT collect, store, transmit or analyze your
          information. It's literally incapable of doing so. Even if it was,
          (which it isn't), that would be horrible, and we'd be horrible people
          for doing that to you. And we like to think we're NOT horrible
          people... just immature smartasses who laugh at fart jokes and write
          misguided parodies for April Fools gags. <br /> <br />
          <br />
          We have to put this here because some people, believe it or not,
          cannot comprehend satire. They just don't get it. I know, what a crazy
          thought. So, to keep everybody happy and sane, and to keep our support
          and social media teams' brains from short-circuiting, we are required
          to state:
          <br /> <br />
          <br /> WINDPASS IS SATIRE, IT DOES NOT DO ANYTHING WITH YOUR DATA.{" "}
          <br /> <br />
          <br />
          Thank you, and may God have mercy on your soul.
        </Flex>
      }
      footerContent={
        <Flex
          sx={{
            height: "40px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StandardButton
            content="OK"
            sx={{
              height: "30px",
              p: "0.1rem",
              fontSize: "1rem",
              width: "70px",
            }}
            onClick={() => {
              setIsAboutOpen(false);
            }}
          />
        </Flex>
      }
    />
  );
}
