import { ReactElement, ReactNode } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";

interface ModalProps {
  headerContent?: ReactNode;
  bodyContent: ReactNode;
  footerContent?: ReactNode;
  sx?: ThemeUIStyleObject;
  width?: string;
  height?: string;
  top?: string;
  left?: string;
  outerSx?: ThemeUIStyleObject;
}
export default function Modal({
  headerContent,
  bodyContent,
  footerContent,
  sx,
  width,
  height,
  top,
  left,
  outerSx,
}: ModalProps): ReactElement {
  return (
    <>
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
          opacity: 0.5,
          zIndex: 3,
        }}
      />
      <Flex
        sx={{
          ...outerSx,
          position: "fixed",
          top: top ?? "60px",
          left: left ?? "100px",
          border: "3px solid black",
          width: width ?? "350px",
          height: height ?? "350px",
          flexDirection: "column",
          zIndex: 10,
          backgroundColor: "#ecbdd9",
        }}
      >
        <Flex sx={{ overflow: "auto", flexDirection: "column", ...sx }}>
          {headerContent && (
            <Flex
              sx={{
                width: "100%",
              }}
            >
              {headerContent}
            </Flex>
          )}
          <Flex>{bodyContent}</Flex>
        </Flex>
        {footerContent && (
          <Flex
            sx={{
              width: "100%",
            }}
          >
            {footerContent}
          </Flex>
        )}
      </Flex>
    </>
  );
}
